<!--
 * @Date: 2023-05
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
-->
<template>
  <div>
    <!-- 挂载微应用节点 -->
    <div :id="STATIC_STATE.registerMountElId"></div>
  </div>
</template>

<script setup>
import { STATIC_STATE } from '@/qiankun/actBusiness/common.js'
import { handleTrack } from '@/utils'

handleTrack('enter_into_haggle_act')
</script>
