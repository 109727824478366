/*
 * @Date: 2023-07
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @Description:
 */
import { getVueVm, getQueryString } from '@/utils'
import { ref } from 'vue'

const { pageStateMap } = usePageState()

// 更改页面状态
export function usePageState () {
  const _this = getVueVm()
  const pageStateMap = {
    success: 'success',
    failed: 'failed',
    pending: 'pending'
  }
  const pageStateConfig = {
    [pageStateMap.success]: {
      name: 'checked',
      color: '#04D175',
      desc: _this.$t('form.paySuccessTip')
    },
    [pageStateMap.failed]: {
      name: 'clear',
      color: '#d93a49',
      desc: _this.$t('form.payErrorTip')
    },
    [pageStateMap.pending]: {
      name: 'underway',
      color: '#009ad6',
      desc: 'waiting'
    }
  }

  const activePageConfig = ref(pageStateConfig[pageStateMap.pending])

  function setState (state) {
    activePageConfig.value = pageStateConfig[state]
  }

  return { pageStateMap, activePageConfig, setState }
}

// 获取各个支付渠道的支付状态
export function usePayChannelState () {
  function getPayerMaxState () {
    const status = getQueryString('status')
    if (!status) return ''
    const map = {
      SUCCESS: pageStateMap.success,
      FAILED: pageStateMap.failed,
      PENDING: pageStateMap.pending
    }
    return map[status] || ''
  }

  return { getPayerMaxState }
}
